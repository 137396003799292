:root {
  margin: 0%;
  padding: 0%;
  box-sizing: border-box;
}
.heading-bg {
  background-color: rgba(0, 0, 0, 0.859);
}
.navtext {
  color: white !important ;
  text-decoration: none !important;
}
.navlink-btn {
  background-color: rgba(128, 128, 128, 0.315);
  border: none;
  border-radius: 5px;
  font-weight: 400;
  text-decoration: none;
  color: black;
  max-width: fit-content;
  min-width: 100px;
}
.active {
  background-color: rgba(0, 0, 0, 0.859);
  color: white;
}
.edit {
  color: green;
  font-weight: 400;
}

.delete {
  color: red;
  font-weight: 400;
}

.cursor {
  cursor: pointer;
}
.eFEHrh div:first-child {
  min-width: 200px !important;
  max-width: 400px !important;
  white-space: pre-wrap !important;
}
.fzvXhU {
  background-color: red !important;
}
.BAfJj {
  border: 1px solid rgba(128, 128, 128, 0.452) !important;
}
.navbar-con {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
}
